.About {
    padding: 10% 5%;
    line-height: 1.25em;
}

.About .ProfileImageContainer {
    margin-top: 5vh;
    padding: 2%;
}

.About .ProfileImage {
    width: 100%;
    height: auto;
    border-radius: 2%;
}

.About .ProfileImage.Mobile {
    display: block;
    opacity: 0.8;
}

.About .ProfileImage.Normal {
    display: none;
}

@media screen and (min-width: 768px) {
    .About {
        font-size: 2vw;
    }
}

@media screen and (min-width: 992px) {
    .About .ProfileImage.Mobile {
        display: none;
    }

    .About .ProfileImage.Normal {
        display: block;
        opacity: 0.5;
    }

    .About .ProfileImage.Normal:hover {
        opacity: 1;
    }
}

@media screen and (min-width: 1200px) {
    .About {
        display: flex;
        font-size: 1vw;
    }

    .About .SelfIntro {
        width: 50%;
        padding-right: 5vw;
    }

    .About .SelfIntro p:first-child {
        margin-top: 0;
    }

    .About .ProfileImageContainer {
        width: 50%;
        margin-top: 0;
    }
}