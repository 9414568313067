.SocialMediaBtn {
    font-size: 1.5em;
    padding: 1vh;
}

.SocialMediaBtn .BtnIcon {
    -webkit-transition: .5s all;
}

.SocialMediaBtn .BtnIcon:hover {
    cursor: pointer;
    -webkit-transform: scale(1.2);
    color: #FFFFFF;
}

.SocialMediaBtn .BtnIcon {
    color: #727980;
}

/*@media screen and (min-width: 992px) {}*/
/*@media screen and (min-width: 1024px) {}*/