.Intro {
    padding: 10%;
    font-size: 3vw;
    height: 80vh;
}

.Intro h1 {
    font-size: 4em;
    border-bottom: 1px solid;
    margin-right: 30%;
}

.Intro h2 {
    font-size: 2em;
}

.Intro p {
    font-size: 1.25em;
    line-height: 1.5em;
    margin: 2vh 0;
}

.MyName {
    font-style: oblique;
}

.MyName h1 {
    margin-right: 0;
}

@media screen and (min-width: 768px) {
    .Intro {
        font-size: 2vw;
    }

    .MyName h1 {
        margin-right: 30%;
    }
}

@media screen and (min-width: 992px) {
    .Intro {
        font-size: 1.5vw;
    }
}

@media screen and (min-width: 1200px) {
    .Intro {
        font-size: 1vw;
        padding: 8% 10%;
        height: 70vh;
    }

    .Intro p {
        font-size: 1em;
    }
}