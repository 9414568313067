.App {
  background-color: #22272d;
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.App-header {
  display: flex;
  height: 10vh;
  width: 100%;
  font-size: 3vw;
  color: #AAABBA;
  background-color: #1f2429;
  box-shadow: 0 0.2vh #191e23;
  opacity: 0.999;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  transition: top 0.3s;
  z-index: 100;
}

.Body {
  margin-top: 10vh;
}

.App-header a {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar{
  width: 5px;
}

::-webkit-scrollbar-thumb{
  background: #27313C;
  border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover{
  background: #37414c;
}

::-webkit-scrollbar-track{
  background-color: transparent;
}

.Content {
  color: #CCCCCC;
  margin: 0 5%;
  height: 100%;
  cursor: default;
}

.Content > div:not(:first-child) {
  border-top: 4px dotted #37414c;
}

@media screen and (min-width: 768px) {
  .App-header {
    font-size: 2vw;
  }

  .Content > div:not(:first-child) {
    border-top: 5px dotted #37414c;
  }
}

@media screen and (min-width: 992px) {
  .App-header {
    font-size: 1.5vw;
  }

  .Content {
    margin: 0 5% 0 10%;
  }
}

@media screen and (min-width: 1200px) {
  .App-header {
    font-size: 1vw;
  }
}