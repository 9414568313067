.PageBox .PageBoxMenu::-webkit-scrollbar {
    display: none;
}

.PageBox {
    width: 100%;
    height: 100%;
    border: #37414c 2px dotted;
    display: flex;
    flex-direction: column;
}

.PageBoxMenu {
    border-right: #37414c 1px dotted;
    overflow-y: auto;
    display: flex;
    min-height: 10%;
    max-height: fit-content;
}

.PageBoxOption {
    flex-shrink: 0;
    width: 50%;
    padding: 1vh 5vw;
    border-bottom: #37414c 1px dotted;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    -webkit-transition: .5s all;
    font-size: 1.5vh;
    line-height: 1.5;
}

.PageBoxOption:hover {
    cursor: pointer;
    background-color: #27313C;
}

.selected {
    background-color: #27313C;
}

.PageBoxContentContainer {
    width: 100%;
    overflow-y: scroll;
    padding: 5%;
    box-sizing: border-box;
    flex: 1;
}

.PageBoxContent {
    display: none;
}

@media screen and (min-width: 768px) {
    .PageBox {
        flex-direction: row;
    }

    .PageBoxMenu {
        width: 25%;
        height:100%;
        display: block;
    }

    .PageBoxOption {
        padding: 0 1vw;
        height: 20%;
        width:100%;
        box-sizing: border-box;
    }

    .PageBoxContentContainer {
        width: 75%;
        height:100%;
    }
}

/*@media screen and (min-width: 920px) {}*/

@media screen and (min-width: 1200px) {
    .PageBoxOption {
        font-size: unset;
    }
}