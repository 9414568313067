.SendEmailBtn {
    color: inherit;
    text-decoration: none;
    border: #CCCCCC 1px solid;
    border-radius: 5px;
    padding: 20px;
    display: inline-block;
    margin-top: 30px;
    -webkit-transition: .5s all;
}

.SendEmailBtn:hover {
    color: #CCCCCC;
    background-color: #505060;
    cursor: pointer;
}

.SendEmailBtn a {
    color: inherit;
    text-decoration: none;
}