[class="SocialMediaBar"] {
    display: flex;
    justify-content: space-evenly;
    font-size: 5vw;
}

.SocialMediaBar.SideBar {
    position: fixed;
    left: 2%;
    bottom: 5%;
    font-size: 1.5vw;
    color: #727980;
    border-top: 2px solid;
    border-bottom: 2px solid;
    padding: 1vh 0;
    display: none;
}

@media screen and (min-width: 992px) {
    .SocialMediaBar.SideBar {
        display: block;
        font-size: 1.5em;
    }

    [class="SocialMediaBar"] {
        font-size: 3vw;
    }
}

@media screen and (min-width: 1200px) {
    [class="SocialMediaBar"] {
        font-size: 1vw;
    }
}