.Contact {
    padding: 5vh 5vw;
}

@media screen and (min-width: 768px) {
    .Contact {
        padding: 5vh 10vw;
    }
}

@media screen and (min-width: 1200px) {
    .Contact {
        padding: 5vh 20vw;
    }
}