.HomeBtn {
    font-size: 1.5em;
    padding: 0 5vw;
    -webkit-transition: .5s all;
}

.HomeBtn:hover {
    color: #CCCCCC;
    cursor: pointer;
    -webkit-transform: scale(1.2);
}

@media screen and (min-width: 1200px) {
    .HomeBtn {
        padding: 0 2vw;
    }
}