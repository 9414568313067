.Navitem {
    margin: 0 3vw;
    -webkit-transition: .5s all;
}

.Navitem:hover, .Navitem:focus-within {
    color: #FFFFFF;
    cursor: pointer;
    -webkit-transform: scale(1.2);
}

@media screen and (min-width: 1200px) {
    .Navitem {
        margin: 0 2vw;
    }
}