body {
  margin: 0;
  font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
  overscroll-behavior: none;
}