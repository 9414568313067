.Experience {
    height: 90vh;
    padding: 10% 5%;
    display: flex;
    line-height: 3vh;
    font-size: 0.9em;
}

.Experience .Title {
    font-size: 1.5em;
    line-height: 1.1em;
}

.Experience .Title a {
    text-decoration: none;
    color: inherit;
}

.Experience .Title a:hover {
    color: #AAABBA;
}

.Experience .Period {
    margin-top: 5%;
    margin-bottom: 10%;
}

.Experience .Duties li {
    margin-bottom: 5%;
}

.Experience .Projects {
    padding-top: 5%;
    border-top: #37414c 2px dotted;
    line-height: 4vh;
}

.Experience .Projects .ProjectDetails {
    font-size: 0.9em;
    margin-bottom: 5%;
}

.Experience .Projects .ProjectTitle {
    margin-bottom: 5%;
    text-decoration: underline;
}

.Experience .Projects > ul {
    list-style-type: none;
    padding: 0;
}

@media screen and (min-width: 768px) {
    .Experience {
        font-size: 2vw;
    }

    .Experience .Period {
        margin-bottom: 0;
    }

    .Experience .Projects .ProjectTitle {
        margin-bottom: 0;
    }
}

/*@media screen and (min-width: 920px) {}*/

@media screen and (min-width: 1200px) {
    .Experience {
        height: 80vh;
        line-height: 5vh;
        font-size: 0.9vw;
    }

    .Experience .Duties li {
        margin-bottom: 0;
    }

    .Experience .Period {
        margin: 0;
    }
}